import apiService from './apiService';

export default class sessionService {

    static getSessionByCode (code) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/session/getByCode/"+code)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

}
