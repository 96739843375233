
import apiService from './apiService';

export default class moduleService {


    static getModuleById (id) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/module/getById/"+id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



}
