<template>
  <div v-if="!module" id="page" class="p-0 d-flex flex-column h-100">
    <bar-component forceLarge="true" />
    <header-home-component />
    <div class="flex-grow-1 d-flex align-items-center">
      <form class="mx-auto" @submit.prevent="enter">
          
        <div>
          <h3>{{ $t("home.type_code") }}</h3>  
          <div class="code">
            <input type="text" v-model="code" maxlength="6" :class="{ 'is-invalid': codeError}">
            <div v-if="codeError" class="invalid-feedback">
                {{codeError}}
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <button type="submit" class="btn btn-lg btn-primary">{{ $t("home.enter_button_label") }}</button>
          </div>
        </div>

        <div v-if="error" class="mt-3 alert alert-danger" role="alert">
          {{error}}
        </div>

      </form>
    </div>
  </div>

  <div v-if="module" id="page" class="p-0 w-100 h-100">
    <iframe-component :moduleUrl="moduleUrl" />
  </div>

</template>

<script>
import BarComponent from '../components/BarComponent.vue'
import HeaderHomeComponent from '../components/HeaderHomeComponent.vue'
import IframeComponent from '../components/IframeComponent.vue'
import moduleService from '../services/moduleService';
import sessionService from '../services/sessionService';
import { io } from 'socket.io-client';
import socketConfig from '../configs/socketConfig';
const base64json = require('base64json');
import $ from "jquery";

export default {
  name: 'HomeView',
  components: {
    'bar-component': BarComponent,
    'header-home-component': HeaderHomeComponent,
    'iframe-component': IframeComponent
  },
  data: function () {
    return {
      code: null,
      error: null,
      module: null,
      session: null,
      socket: null,
      data: null,
      moduleUrl: null
    }
  },
  methods: {
      base64(data)
      {
        return base64json.stringify(data);
      },
      connectSocket()
      {
        this.socket = io(process.env.VUE_APP_SOCKET_URL);

        this.socket.on('connect_error', () => {
          console.log("connect_error");
        });

        this.socket.on("disconnect", (reason) => {
          
              console.log("disconnect reason: "+reason);
      
              /*
              if ( (reason === "io server disconnect") ) {
                  // the disconnection was initiated by the server, you need to reconnect manually
              }
              */
            
              
          });

        this.socket.on("connect", () => {
          console.log("connected");

          this.socket.emit(socketConfig.JOIN_SESSION, this.session.id);
        });

        this.socket.on(socketConfig.PILOT_SESSION, (data) => {
          
          this.data = this.base64(data);
          this.setModuleUrl(this.data);
          
        });

        this.socket.on(socketConfig.PILOT_SESSION_LIVE, (liveData) => {
          this.liveData = liveData;

          var iframe = document.getElementById('moduleIframe');
          if(iframe)
          {
            iframe.contentWindow.postMessage(this.liveData, "*");
          }
          
        });

        window.addEventListener("message", (event) => 
        {
            if(event.data == "getLiveData")
            {
              var iframe = document.getElementById('moduleIframe');
              iframe.contentWindow.postMessage(this.liveData, "*");
            }

        }, false);
        
        
      
    },
    setModuleUrl(data)
    {
      this.moduleUrl = (data) ? this.module.url+data : this.module.url; 
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'code':
              this.codeError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
          }
        }
      }else
      {

        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    },
    getModule(id)
    {
      moduleService.getModuleById(id).then(
          module => {
            this.data = (this.session.data) ? this.base64(this.session.data) : '';
            this.module = module;
            this.setModuleUrl(this.data);
            this.connectSocket();
          },
          errors => {
            console.log(errors)
            
          }
        );
    },
    enter() {

      if(this.code)
      {
        this.error = null;

        sessionService.getSessionByCode(this.code).then(
          session => {
            
            if(session)
            {
              this.session = session;
              this.getModule(session.module);
            } else
            {
              this.error = this.$t("home.session_unknown");
            }
            
          },
          errors => {
            if(errors.msg)
            {
              this.parseErrors({msg: errors.msg});
            }else
            {
              this.parseErrors(errors.response.data);
            }
            
          }
        );
      }
      
    }
  }
}

</script>


<style scoped lang="scss">
  h2 {
    font-size: 22px;
    font-weight: 700;
  }

  .code {
    background-image: url(../assets/images/bg-input-code.svg);
    background-repeat: no-repeat;
    background-size: 280px 65px;
  }

  .code input, .code input:active,.code input:focus {
    height: 65px;
    width: 280px;
    border: none;
    background: none;

    font-size: 40px;
    font-weight: 700;
    letter-spacing: 20px;
    color: #A7CA3D;
    line-height: 65px;
    padding-left: 15px;
    text-transform: uppercase;

    font-family: 'Roboto Mono', monospace;
  }
</style>