
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';

const defaultMeta = {
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: defaultMeta
  }
];


var router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes
})


export default router
