<template>
    <header class="d-flex align-items-center ps-4">
      <h1>{{ $t("home.title") }}</h1>
    </header>
  
</template>

<script>
export default {
  name: 'HeaderHomeComponent'
}
</script>

<style scoped>

  header {
    height: 212px;
    background-color: #28348B;
    background-image: url(../assets/images/home-bg.png);
    background-position: center right;
    background-repeat: no-repeat;
  }

  h1 {
    color: white;
    font-size: 22px;
    font-weight: 700;
  }
</style>
