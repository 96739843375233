<template>
  <iframe id="moduleIframe" name="moduleIframe" :src="url" frameborder="0" class="p-0 w-100 h-100"></iframe>
</template>

<script>

export default {
  name: 'IframeComponent',
  data: function () {
    return {
      url: null
    }
  },
  props: {
    moduleUrl: String
  },
  watch: 
  { 
    moduleUrl: function() {
      this.url = "";
      setTimeout(() => {
        this.url = this.moduleUrl;
      }, 300);
    }
  },
  mounted()
  {
    this.url = "";
      setTimeout(() => {
        this.url = this.moduleUrl;
      }, 300);
  }
}
</script>

<style scoped>

</style>
