<template>
  <div class="bar" :class="{forceLarge, forceLarge}">

  </div>
  
</template>

<script>
export default {
  name: 'MarkComponent',
  props: {
    forceLarge: Boolean
  }
}
</script>

<style scoped>
.bar {
  background-color: white;
  min-height: 72px;
  min-width: 180px;
  background-image: url(../assets/images/logo.png);
  background-repeat: no-repeat;
  background-position-x: 20px;
  background-position-y: center;
}

@media (orientation: landscape) {
  .bar {
    float: left;
  }
}

@media (orientation: portrait) {
  .bar {
    width: 100%;
  }
}

.bar.forceLarge {
  float: initial;
  width: 100%;
}


</style>
